@use '@/styles/utils/mixins.scss' as *;

.ctaBannerWrapper {
  margin-top: 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10rem;
  @include tab() {
    margin-top: 5.125rem;
    gap: 3.25rem;
  }

  .ctaBannerContent {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 3.75rem;
    width: 100%;
    @include tab() {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  .sectionRightContent {
    width: 100%;
    grid-column: span 2;
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    @include tab() {
      grid-column: span 1;
    }
  }

  .description {
    text-align: left;
    a {
      color: $primary;
      &:hover,
      &:focus,
      &:active {
        color: $primaryAlt;
      }
    }
  }
  .contactList {
    display: flex;
    gap: 2.5rem;
    flex-wrap: wrap;
    @include tab() {
      flex-direction: column;
      flex-wrap: nowrap;
    }
    .contactBox {
      height: 100%;
      width: calc(50% - 2.5rem);
      border-radius: 1.25rem;
      border: 0.063rem solid $white;
      padding: 1.5rem 1.25rem;
      background-color: $white;
      transition: all 0.2s ease-in-out;

      &:hover {
        border-color: $primaryAlt;
      }

      @include tab() {
        width: calc(50% - 2.5rem);
      }

      @include mob() {
        width: 100%;
        text-align: center;
      }
      .contactNumber {
        font-weight: 500;
        font-size: 1.5rem;
        color: $dark;
      }
    }
  }
  .bannerImageContainer {
    position: relative;
    overflow: hidden;
    border-radius: 1.25rem;
    width: 100%;
    height: 40rem;

    @include mob() {
      height: 29.25rem;
      margin: 0 1.875rem;
      width: calc(100% - 3.75rem);
    }
  }

  .bannerImageWrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    transition: all 0.1s ease;
  }

  .bannerImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
